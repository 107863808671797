import { Partition } from '@snapchat/graphene';
import { Template } from '@snapchat/snap-design-system-marketing';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  theme: {
    defaultPageBackgroundColor: 'Black',
    breakTemplates: [
      Template.Straight,
      Template.Straight,
      Template.Straight,
      Template.Straight,
      Template.Straight,
      Template.Straight,
    ],
    heroBreakTemplate: Template.Straight,
  },
  domainName: 'arcadiacreativestudio.com',

  siteMap: {
    customPaths: {
      // Top level domain, should include cookie-settings in site map
      '/cookie-settings': { isLocalized: true },
    },
  },
  trackingSettings: {
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.ARCADIA },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleAnalyticsId: 'G-SC07FPS0C7',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'arcadia',
        dsn: 'https://d249d075ec6d46ba9211a8d4639f21ac@sentry.sc-prod.net/157',
      },
    ],
  },
};
